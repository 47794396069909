package com.sychev.mashaplus.utils


object Resources {

    object Strings {
        const val yarkost_blesk =
            "Яркость, блеск и целое музыкальное представление для вас и ваших гостей. Два блока — мировые хиты всех времен и любимые песни 90-ых в новой аранжировке от наших вокалистов и музыкантов, энергичные танцы и шикарные костюмы — вам точно нужно это прочувствовать!"
        const val vocalisty = "Вокалисты"
        const val vocalist = "Вокалист"
        const val kontakty = "Контакты"
        const val vocalisty_uppercase = "ВОКАЛИСТЫ"
        const val vokalistky = "Вокалистки"
        const val minimalniy_komplekt =
            "У вокалиста есть минимальный комплект оборудования, необходимый для подзвучивания"
        const val kolichestvo_blokov = "Количество блоков оговаривется заранее, возможны любые варианты"
        const val duet = "Дуэт"
        const val ogromnym_priemushestvom = "Огромным приемуществом нашего проекта является взаимозаменяемость"
        const val ostavit_zayavku = "Оставить заявку"
        const val otpravit = "Отправить"
        const val go_back = "Вернуться"
        const val muzik_project =
            "MASHA PLUS – музыкальный проект, который реализует все форматы живых выступлений. От соло-вокалистов до кавер бэнда"
        const val sozday_meropriyatie = "Создай мероприятие по своим правилам"
        const val sostav_gruppy = "18  ВОКАЛИСТОВ  10  ИНСТРУМЕНТАЛИСТОВ  5  ЛЕТ"
        const val text_14 = "15"
        const val text_10 = "10"
        const val text_5 = "5"
        const val nashi_yslygi = "НАШИ УСЛУГИ"
        const val vocalnoye_show_uppercase = "ВОКАЛЬНОЕ ШОУ"
        const val vacalnoye_show = "Вокальное шоу"
        const val repertuar = "Репертуар"
        const val duet_uppercase = "ДУЭТ"
        const val ultima_band = "ULTIMA BAND"
        const val vocalistky_uppercase = "ВОКАЛИСТКИ"
        const val instrymentalisty = "Инструменталисты"
        const val let_text = "Лет"
        const val liza_ionovoa = "ЛИЗА\nИОНОВА"
        const val natalya_molodojen = "НАТАЛЬЯ\nМОЛОДОЖЁН"
        const val ula_lank = "ЮЛИЯ ЛАНК"
        const val jia = "ДЖИА"
        const val alina_viera = "АЛИНА\nВИЕРА"
        const val yla = "УЛА"
        const val diana = "ДИАНА"
        const val elena = "ЕЛЕНА\nВЕСКРЕСЕНСКАЯ"
        const val milana = "МИЛАНА"
        const val alya = "АЛЯ"
        const val nastya = "НАСТЯ"
        const val polya = "ПОЛЯ"
        const val dora = "ДОРА"
        const val andrey = "АНДРЕЙ \nМАХОРИН"
        const val ilya = "ИЛЬЯ\nОКУЛОВ"
        const val ilya_star = "ИЛЬЯ \nСТАРЧИКОВ"
        const val marat = "МАРАТ"
        const val roman = "РОМАН"
        const val princip_constryktora =
            "Мы работаем по принципу конструктора, когда каждый заказчик может составить дуэт из любых понравившихся вокалистов. А так же из вокалистов и инструменталистов "
        const val sasha_plus_alya = "САША + АЛЯ"
        const val lena_plus_liza = "ЛЕНА + ЛИЗА"
        const val ula_plus_yla = "ЮЛЯ + УЛА"
        const val ilya_plus_andrey = "ИЛЬЯ + АНДРЕЙ"
        const val dora_plus_alina = "ДОРА + АЛИНА"
        const val marat_plus_yla = "МАРАТ  + УЛА"
        const val milana_plus_jia = "МИЛАНА + ДЖИА"
        const val lena_plus_andrey = "ЛЕНА + АНДРЕЙ"
        const val alya_plus_diana = "АЛЯ + ДИАНА"
        const val spb = "г. Санкт - Петербург"
        const val ostavte_nomer = "Оставьте свой номер, создадим ваше мероприятие вместе"
        const val success_thx_message = "Спасибо!"
        const val success_message = "В скором времени с вами свяжутся"
        const val bolshe_o_nas = "Больше о нас"
        const val vvedite_nomer_telefona_hint = "Введите номер телефона"
        const val jiviye_vystuplenya = "ЖИВЫЕ ВЫСТУПЛЕНИЯ"
        const val repertuar_uppercase = "РЕПЕРТУАР"
        const val prices = "ЦЕНЫ"
        const val important_questions = "ВАЖНЫЕ ВОПРОСЫ"
        const val created_by1 =
            "Музыкальный проект был создан 5 лет назад "
        const val created_by2 = "Марией Борониной"
        const val created_by3 = " - певицей, педагогом по эстрадному вокалу, бэк - вокалисткой "
        const val created_by4 = "Татьяны Булановой"
        const val bak_vocalistka = "Бэк-вокалистка: "
        const val bak_vocalistka_desc =
            "Татьяны Булановой, Федора Чистякова и гр. “Ноль”, гр. “КАФЕ”, \"SPb PINK FLOYD show\", “Братья Жемчужные” и др"
        const val studio_bak_vocal = "Студийный бэк вокал: "
        const val studio_bak_vocal_desc = "Александр Розенбаум, \"Тараканы\", \"Король и шут\", Юлия Михальчик."
        const val bolee_ten = "Более 10 лет "
        const val bolee_ten_desc =
            "работы в production studion на радиостанциях: \" Русское радио\", \"Эрмитаж\", \"Эльдорадио\", \"Европа плюс\", \"Радио Модерн\", \"Радио Шансон\""
        const val partners_title = "ПОДДЕРЖИВАЕМ СОТРУДНИЧЕСТВО С РЕСТОРАНАМИ УЖЕ НЕСКОЛЬКО ЛЕТ:"
        const val photo = "ФОТО"
        const val live_perfomances = "ЖИВЫЕ ВЫСТУПЛЕНИЯ"
        const val phone_num = "+7 (931) 951-20-00"
    }

    object Images {
        const val masha_logo = "/masha_logo.png"
        const val main_photo = "/main_photo.png"
        const val main_photo_mobile = "/main_photo_mobile.png"
        const val clyaksa1 = "/clyaksa.png"
        const val clyaksa_2 = "/clyaksa_2.png"
        const val liza_vok = "/liza_vok.png"
        const val ula_vok = "/ula_vok.png"
        const val natalya_vok = "/natalya_vok.png"
        const val diana_vok = "/diana_vok.png"
        const val milana_vok = "/milana_vok.png"
        const val elena_vok = "/elena_vok.png"
        const val dora_vok = "/dora_vok.png"
        const val alya_vok = "/alya_vok.png"
        const val polya_vok = "/polya_vok.png"
        const val nastya_vok = "/nastya_vok.png"
        const val djia_vok = "/djia_vok.png"
        const val andrey_vok = "/andrey_vok.png"
        const val ilya_vok = "/ilya_vok.png"
        const val ilay_star_vok = "/ilya_starchikov_vok.png"
        const val marat_vok = "/marat_vok.png"
        const val roman_vok = "/roman_vok.png"
        const val arrow_left = "/arrow_left.png"
        const val arrow_right = "/arrow_right.png"
        const val arrow_left_painted = "/arrow_left_painted.png"
        const val arrow_right_painted = "/arrow_right_painted.png"
        const val ic_phone = "/ic_phone.png"
        const val maria_boronina = "/maria_boronina.png"
        const val partner_bahroma_logo = "/partner_bahroma_logo.png"
        const val partner_feromon_logo = "/partner_feromon_logo.png"
        const val partner_grill_house_logo = "/partner_grill_house_logo.png"
        const val partner_hochy_harcho_logo = "/partner_hachy_harcho_logo.png"
        const val partner_kind_gruzin_logo = "/partner_kind_gruzin_logo.png"
        const val partner_mamaliga_logo = "/partner_mamaliga_logo.png"
        const val partner_peperoni_logo = "/partner_peperoni_logo.png"
        const val partner_vse_horosho_logo = "/partner_vse_horosho_logo.png"
        const val duet_1 = "/duet_1.png"
        const val duet_2 = "/duet_2.png"
        const val duet_3 = "/duet_3.png"
        const val duet_4 = "/duet_4.png"
        const val duet_5 = "/duet_5.png"
        const val duet_6 = "/duet_6.png"
        const val duet_7 = "/duet_7.png"
        const val duet_8 = "/duet_8.png"
        const val ic_menu = "/menu.png"
    }

}