import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobals
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.components.animation.registerKeyframes
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main() {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { com.sychev.mashaplus.pages.HomePage() }
        ctx.router.register("/about") { com.sychev.mashaplus.pages.AboutPage() }
        ctx.router.register("/success") { com.sychev.mashaplus.pages.successPage.SuccessPage() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.additionalSilkInitialization = { ctx ->
        com.varabyte.kobweb.silk.init.initSilkWidgets(ctx)
        com.varabyte.kobweb.silk.init.initSilkWidgetsKobweb(ctx)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.HeadlineTextStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.HugeThinTextStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.SectionTitleStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.Headline2TextStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.ButtonTextStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.SubheadlineBoldTextStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.ModalTitleTextStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.ModalDescriptionTextStyleCentered)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.ModalDescriptionTextStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.NavigationHeadlineTextStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.MainTitleTextStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.MainTitleTextStyleHybrid)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.SubheadlineRegularStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.SubheadlineRegularStyleHybrid)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.SubheadlineRegularStyleMobile)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.SubheadlineRegularStyleLaptop)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.SubheadlineBoldStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.SubheadlineBoldStyleMobile)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.SubheadlineBoldStyleLaptop)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.SubheadlineBoldStyleHybrid)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.CreatorTextStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.CreatorTextStyleMobile)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.CreatorCardTextStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.CreatorTextStyleLaptop)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.PartnersTitleTextStyleMobile)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.CreatorCardTextStyleMobile)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.PartnersTitleTextStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.TitleOutlinedStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.TitleStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.TitleStyleHybrid)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.TitleStyleMobile)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.GridTextTitle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.GridTextTitleHybrid)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.GridTextTitleMobile)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.VocalistTextTitle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.VocalistTextTitleMobile)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.SmallTitleStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.HugeTextStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.CarouselTextTitle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.BottomCaptionTitle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.SuccessTitle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.BottomCaptionTitleMobile)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.BottomCaptionTitleLaptop)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.SmallRegularTextStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.BottomPromoItemTextStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.OutlineButtonTextStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.OutlineButtonTextStyleMobile)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.OutlineButtonTextSmallStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.components.layouts.MarkdownStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.components.layouts.PageContentStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.components.sections.FooterStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.components.sections.NavHeaderStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.components.widgets.DotsIndicatorStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.HeroContainerStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.VideoStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.SectionContainerStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.LogoStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.LogoStyleSmall)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.HomeGridCellStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.SectionPhotoStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.MainPhotoStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.MainPhotoStyleMobile)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.SecondaryPhotoStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.BottomPhotoGradientStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.SliderPhotoStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.ClyaksImageStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.ClyaksImageStyleMobile)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.Clyaks2ImageStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.Clyaks2ImageLaptop)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.Clyaks2ImageStyleMobile)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.ArrowImageStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.ArrowImageStyleMobile)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.ArrowImageStyleLaptop)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.GridPhotoStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.PersonWithCardPhotoStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.PersonWithCardPhotoStyleMobile)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.PersonWithCardPhotoStyleLaptop)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.WrapPhotoStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.FillPhotoStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.CreatorPhotoStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.CreatorPhotoStyleMobile)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.CreatorPhotoStyleLaptop)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.InsidePhotoStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.DuetPhotoStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.DuetPhotoStyleMobile)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.DuetPhotoStylLaptop)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.CarouselPhotoStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.OurServicesGridStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.CreatorSectionGridStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.CreatorSectionGridStyleLaptop)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.PartnersSectionGridStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.DuetSectionGridStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.DuetSectionGridStyleHybrid)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.pages.main.widgets.VocalistsSectionGridStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.utils.VideoFrameStyle)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.utils.VideoFrameStyleMobile)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.utils.VideoFrameStyleLaptop)
        ctx.theme.registerComponentStyle(com.sychev.mashaplus.utils.VideoFrameStyleHybrid)
        ctx.theme.registerComponentVariants(com.sychev.mashaplus.CircleButtonVariant)
        ctx.theme.registerComponentVariants(com.sychev.mashaplus.UncoloredButtonVariant)
        ctx.theme.registerComponentVariants(com.sychev.mashaplus.DefaultButtonVariant)
        ctx.theme.registerComponentVariants(com.sychev.mashaplus.GradientCircularButtonVariant)
        ctx.theme.registerComponentVariants(com.sychev.mashaplus.OutlinedGradientCircularButtonVariant)
        ctx.theme.registerComponentVariants(com.sychev.mashaplus.OutlinedCircularButtonVariant)
        ctx.theme.registerComponentVariants(com.sychev.mashaplus.OutlinedCircularButtonVariantMobile)
        ctx.stylesheet.registerKeyframes(com.sychev.mashaplus.components.sections.SideMenuSlideInAnim)
        ctx.stylesheet.registerKeyframes(com.sychev.mashaplus.pages.LogoSlideInAnim)
        ctx.stylesheet.registerKeyframes(com.sychev.mashaplus.pages.MainPhotoSlideInAnim)
        ctx.stylesheet.registerKeyframes(com.sychev.mashaplus.utils.StubAnimation)
        ctx.stylesheet.registerKeyframes(com.sychev.mashaplus.utils.FadeInAnim)
        ctx.stylesheet.registerKeyframes(com.sychev.mashaplus.utils.SlideRightAnim)
        ctx.stylesheet.registerKeyframes(com.sychev.mashaplus.utils.SlideLeftAnim)
        com.sychev.mashaplus.initColorMode(ctx)
        com.sychev.mashaplus.initSiteStyles(ctx)
        com.sychev.mashaplus.initTheme(ctx)
    }

    // For SEO, we may bake the contents of a page in at build time. However, we will
    // overwrite them the first time we render this page with their composable, dynamic
    // versions. Think of this as poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    val root = document.getElementById("root")!!
    while (root.firstChild != null) { root.removeChild(root.firstChild!!) }

    AppGlobals.initialize(mapOf("title" to "Masha_plus"))
    renderComposable(rootElementId = "root") {
        com.sychev.mashaplus.AppEntry {
            router.renderActivePage { renderWithDeferred { it() } }
        }
    }
}
